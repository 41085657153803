<template>
  <v-row>
    <v-row v-if="!loading">
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="500">
          <v-card>
            <v-card-title class="text-h5">
              Delete private app
            </v-card-title>
            <v-card-text>
              Are you sure you want to delete <span class="font-weight-bold">{{ this.app.appName }}</span> ? This will
              revoke all your credentials and may break implementations of your app or storefront. This action can't be
              reversed.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="" outlined @click="cancel">
                Cancel
              </v-btn>
              <v-btn color="error" depressed @click="deleteApp">
                Delete this private app
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-col cols="12">
        <v-btn class="text-uppercase" color="grey lighten-3" @click="$router.push({ name: 'website.apps-private' })">
          <v-icon color="" left>
            mdi-arrow-left
          </v-icon>
          Private apps
        </v-btn>
      </v-col>
      <v-col cols="12">
        <span
          class="page-title display-1 font-weight-bold grey--text text--darken-2"
          v-if="$route.name === 'website.apps-private.edit'"
        >
          {{ app.appName }}
        </span>
        <span class="page-title display-1 font-weight-bold grey--text text--darken-2" v-else>
          {{ $t('Create private apps') }}
        </span>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4">
            <div class="font-weight-bold text-subtitle-1">
              App details
            </div>
            <div class="grey--text pt-3">
              These details can help you keep track of your private apps and stay informed about changes that affect
              your app.
            </div>
          </v-col>
          <v-col cols="8">
            <v-card class="mx-auto" elevation="1">
              <v-card-title class="text-subtitle-1 font-weight-bold grey--text text--darken-2 py-0">
                Private app name
              </v-card-title>
              <v-card-text>
                <v-text-field
                  height="40px"
                  v-model="app.appName"
                  :rules="[() => !!app.appName || 'This field is required']"
                ></v-text-field>
              </v-card-text>
              <v-card-title class="text-subtitle-1 font-weight-bold grey--text text--darken-2 py-0">
                Emergency developer email
              </v-card-title>
              <v-card-text>
                <v-text-field height="40px" v-model="app.supportEmail" :rules="emailRules"></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4">
            <div class="font-weight-bold text-subtitle-1">
              Admin API
            </div>
            <div class="grey--text pt-3">
              These permissions determine what data this private app can access. You should enable only what is
              necessary for your app to work.
            </div>
            <div class="grey--text pt-3">
              Treat the API key and password like you would any other password, since whoever has access to these
              credentials has API access to the store.
            </div>
            <div class="grey--text pt-3">
              Read our <span class="private-app">API documentation</span> and our
              <span class="private-app">Lattehub API License and Terms of Use.</span>
            </div>
          </v-col>
          <v-col cols="8">
            <v-card class="mx-auto" elevation="1">
              <div v-if="$route.name === 'website.apps-private.edit'">
                <v-card-text class="font-weight-bold black--text text-subtitle-1 py-0 pt-5">
                  API key
                </v-card-text>
                <v-card-text class="grey--text text-subtitle-1 py-0 d-flex justify-center align-center">
                  <input readonly type="text" class="info-input" v-model="app.apiKey" ref="api-key" />
                  <v-btn class="" @click="copy(`api-key`)">
                    <v-icon>mdi-checkbox-multiple-blank-outline</v-icon>
                  </v-btn>
                </v-card-text>
                <v-card-text class="font-weight-bold black--text text-subtitle-1 py-0 pt-4">
                  password
                </v-card-text>
                <v-card-text class="password grey--text text-subtitle-1 py-0 d-flex justify-center align-center">
                  <input readonly :type="type" class="info-input pr-16" v-model="app.appPassword" ref="password" />
                  <div class="icon-password" @click="setType">show</div>
                  <v-btn class="" @click="copy(`password`)">
                    <v-icon>mdi-checkbox-multiple-blank-outline</v-icon>
                  </v-btn>
                </v-card-text>
                <!-- <v-card-text class="font-weight-bold black--text text-subtitle-1 py-0 pt-4">
                  Example URL
                </v-card-text>
                <v-card-text class="grey--text text-subtitle-1 py-0 d-flex justify-center align-center">
                  <input readonly type="text" class="info-input" v-model="app.email" ref="example-url" />
                  <v-btn class="" @click="copy(`example-url`)">
                    <v-icon>mdi-checkbox-multiple-blank-outline</v-icon>
                  </v-btn>
                </v-card-text> -->
                <v-card-text>
                  Private applications authenticate with Lattehub through basic HTTP authentication, using the URL
                  format https://apikey:password@hostname/admin/resource.json
                </v-card-text>
                <!-- <v-card-text class="font-weight-bold black--text text-subtitle-1 py-0  pt-4">
                  Shared Secret
                </v-card-text>
                <v-card-text class="grey--text text-subtitle-1 py-0 d-flex justify-center align-center">
                  <input readonly type="text" class="info-input" v-model="app.email" ref="shared-secret" />
                  <v-btn class="" @click="copy(`shared-secret`)">
                    <v-icon>mdi-checkbox-multiple-blank-outline</v-icon>
                  </v-btn>
                </v-card-text>
                <v-card-text class="py-3">
                  Secrets are used to
                  <span class="private-app">validate the integrity of webhooks.</span>
                </v-card-text> -->
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </div>
              <v-card-text
                class="font-weight-bold black--text text-subtitle-1"
                v-if="$route.name === 'website.apps-private.edit'"
              >
                ADMIN API PERMISSIONS
              </v-card-text>
              <v-card-text class="" v-else>
                Your API credentials will be generated when you Save.
              </v-card-text>
              <v-card-text>
                <v-row>
                  <v-col cols="8">
                    <div class="">Product</div>
                    <div class="fs-13">read_product, write_product</div>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      :items="items"
                      item-text="label"
                      item-value="id"
                      persistent-hint
                      v-model="scopes.product"
                      :return-object="false"
                      single-line
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-row>
                  <v-col cols="8">
                    <div class="">Order</div>
                    <div class="fs-13">read_order, write_order</div>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      :items="items"
                      item-text="label"
                      item-value="id"
                      persistent-hint
                      v-model="scopes.order"
                      :return-object="false"
                      single-line
                    ></v-select>
                  </v-col>
                </v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-btn
          color="deep-orange accent-3"
          @click="deletePrivateApp"
          v-if="$route.name === 'website.apps-private.edit'"
        >
          <span class="white--text">Delete this private app</span>
        </v-btn>
      </v-col>
      <v-col cols="8" class="d-flex justify-end">
        <v-btn outlined class="mr-2" @click="$router.push({ name: 'website.apps-private' })">
          <span class="black--text">Cancel</span>
        </v-btn>
        <v-btn color="primary" @click="savePrivateApp">
          <span class="white--text">Save</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12">
        <v-skeleton-loader class="mx-auto" type="list-item, list-item, list-item, list-item"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import { privateAppApi } from '@/apis/privateApp';
export default {
  data() {
    return {
      loading: true,
      dialog: false,
      show: false,
      items: [
        // {
        //   id: 'no-access',
        //   label: 'No access',
        // },
        {
          id: 'read',
          label: 'Read access',
        },
        {
          id: 'write',
          label: 'Write access',
        },
      ],
      scopes: {
        product: 'read',
        order: 'read',
      },
      app: {
        appName: '',
        supportEmail: '',
        scopes: ['READ_PRODUCT', 'READ_ORDER'],
      },
      type: 'text',
      emailRules: [
        () => !!this.app.supportEmail || 'E-mail is required',
        () => /.+@.+\..+/.test(this.app.supportEmail) || 'E-mail must be valid',
      ],
    };
  },
  methods: {
    async savePrivateApp() {
      if (this.$route.name === 'website.apps-private.create') {
        try {
          let app = await privateAppApi.createPrivateApp(this.app);
          console.log(app);
          window.location.href = `/admin/app/private/${app.data.privateApp._id}`;
          // this.$router.push({ name: 'website.apps-private.edit', params: { id: app.data.privateApp._id } });
        } catch (e) {
          console.log('error', e);
        }
      } else if (this.$route.name === 'website.apps-private.edit') {
        await privateAppApi.updatePrivateApp(this.app);
        this.loading = true;
        try {
          let app = await privateAppApi.getPrivateAppId(this.$route.params.id);
          this.app = app.data.privateApp;
          if (this.app.scopes.includes('READ_PRODUCT')) {
            this.scopes.product = 'read';
          } else if (this.app.scopes.includes('WRITE_PRODUCT')) {
            this.scopes.product = 'write';
          }
          if (this.app.scopes.includes('READ_ORDER')) {
            this.scopes.order = 'read';
          } else if (this.app.scopes.includes('WRITE_ORDER')) {
            this.scopes.order = 'write';
          }
          if (this.app.scopes.includes('READ_PRODUCT')) {
            this.scopes.product = 'read';
          } else if (this.scopes.product === 'write') {
            this.scopes.product = 'write';
          }
        } catch (error) {
          console.log('error', error);
        }
      }
    },
    copy(ref) {
      let copy = this.$refs[ref];
      copy.select();
      navigator.clipboard.writeText(copy.value);
    },
    setType() {
      if (this.type === 'text') {
        this.type = 'password';
      } else if (this.type === 'password') {
        this.type = 'text';
      }
    },
    deletePrivateApp() {
      this.dialog = true;
    },
    async deleteApp() {
      try {
        await privateAppApi.deletePrivateApp(this.app._id);
        this.$store.commit('setMessages', {
          messages: 'Delete app successfully!',
          type: 'success',
        });
        this.$router.push({ name: 'website.apps-private' });
      } catch (error) {
        this.$store.commit('setMessages', {
          messages: 'Error',
          type: 'error',
        });
      }
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
    },
  },
  watch: {
    scopes: {
      deep: true,
      handler() {
        this.app.scopes = [];
        if (this.scopes.product === 'read') {
          this.app.scopes.push('READ_PRODUCT');
        } else if (this.scopes.product === 'write') {
          this.app.scopes.push('WRITE_PRODUCT');
        }
        if (this.scopes.order === 'read') {
          this.app.scopes.push('READ_ORDER');
        } else if (this.scopes.order === 'write') {
          this.app.scopes.push('WRITE_ORDER');
        }
      },
    },
  },
  async created() {
    if (this.$route.name === 'website.apps-private.edit') {
      this.loading = true;
      try {
        let app = await privateAppApi.getPrivateAppId(this.$route.params.id);
        this.app = app.data.privateApp;
        if (this.app.scopes.includes('READ_PRODUCT')) {
          this.scopes.product = 'read';
        } else if (this.app.scopes.includes('WRITE_PRODUCT')) {
          this.scopes.product = 'write';
        }
        if (this.app.scopes.includes('READ_ORDER')) {
          this.scopes.order = 'read';
        } else if (this.app.scopes.includes('WRITE_ORDER')) {
          this.scopes.order = 'write';
        }
        if (this.app.scopes.includes('READ_PRODUCT')) {
          this.scopes.product = 'read';
        } else if (this.scopes.product === 'write') {
          this.scopes.product = 'write';
        }
      } catch (error) {
        console.log('error', error);
      }
      this.loading = false;
    } else if (this.$route.name === 'website.apps-private.create') {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.fs-13 {
  size: 13px;
}
.v-select__selections {
  input {
    display: none;
  }
}
.private-app {
  color: #0091eb;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.info-input {
  height: 40px;
  width: 100%;
  padding: 0px 10px;
  border: 1px solid grey;
  border-radius: 5px;
}
.password {
  position: relative;
  .icon-password {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15%;
    color: #0091eb;
  }
}
</style>
